<template>
  <div>
    <div class="container">
      <div class="row mt-5">
        <div class="col-6">
          <div class="card" style="border-radius:10px;box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); height: 505px">
            <div class="card-header" style="border-bottom: 0px; background-color: #EAEAEA; border-radius: 10px 10px 0px 0px; height:50px;">
              <span class="card-title h6 mt-2 " style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 14px;color: #545454;">Select Funding Method</span>
            </div>

            <div v-if="paymentMethods" class="card-body" style="padding: 0; overflow-y: scroll">
              <div class="col" style="overflow-y: auto">
                <div v-for="(method, i) in paymentMethods" 
                  v-bind:key="method.brand">
                  
                  <div class="payment-method d-flex" style="height: 70px;">
                    <div class="logo p-3" style="width:70px;">
                      <img class="mx-auto" style="max-height: 40px; max-width: 50px" :src="method.logo" alt="">
                    </div>

                    <div class="mr-auto my-auto">
                      
                      <div class="f-roboto f-12" style="">
                        <div style="margin-top:15px;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 12px;color: #545454;">
                          {{ method.brand }}
                        </div>

                        <div style="margin-top:5px;font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px;color: #8B8B8B;" v-html="method.tagline"></div>
                      </div>
                    </div>

                    <div v-if="method.sandbox " class="badge badge-warning mx-2 my-auto">Sandbox Mode</div>

                    <div class="my-auto mr-4" v-if="user.state == 'SOFT_BANNED' && method.isChargebackEnabled">
                      <button type="button" @click="disabledMethod" class="btn btn-dark btn-md btn-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Your account has been limited due to a negative balance. Please contact customer support to resolve this issue.">
                        <font-awesome-icon icon="info"  />
                      </button>
                    </div>
                    <div v-else-if="method.disabled"></div>
                    <div v-else class="p-3" >
                      <button v-if="!method.component " disabled class="btn btn-primary withdraw-button f-roboto f-12" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 12px;text-transform: uppercase;color: #FFFFFF;width:120px!important;padding-left:0px;padding-right:0;text-align:center;">COMING SOON</button>
                      <button v-else-if="selectedPaymentMethod && method.method != selectedPaymentMethod.method " @click="select(method.id)" class="btn btn-primary withdraw-button f-roboto f-12" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 12px;text-transform: uppercase;color: #FFFFFF;width:120px!important;padding-left:0px;padding-right:0;text-align:center;">SELECT</button>
                      <button v-else-if="selectedPaymentMethod && method.method == selectedPaymentMethod.method " @click="select(method.id)" class="btn btn-success withdraw-button f-roboto f-12" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 12px;text-transform: uppercase;color: #FFFFFF;width:120px!important;padding-left:0px;padding-right:0;text-align:center;">SELECTED</button>
                    </div>
                  </div>
                  <div v-if="i != paymentMethods.length-1" class="hr"></div>
                </div>
              </div>
            </div>
            <div v-else-if="errorLoading" class="mx-auto my-auto">Unable to load. Please try again later.</div>
            <div v-else class="loader mx-auto my-auto"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="card" style="border-radius:10px;box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); height: 505px;">
            <div class="card-header" style="border-bottom: 0px; background-color: #EAEAEA; border-radius: 10px 10px 0px 0px; height:50px;">
              <span class="card-title h6" style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 14px;color: #545454;">Select Deposit Amount</span>
            </div>

            <div class="card-body" style="height: 190px;">
              <component v-if="selectedPaymentMethod" :is="selectedPaymentMethod.component" @on:failureLoading="selectPrevious" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';

const validInputs = [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "." ];

export default {
  data() {
    return {
      paymentMethods: [
        { id: "coinbase", method: "coinbase", logo: require("../assets/bitcoin.png"), brand: "Bitcoin", sandbox: false, component: () => import('../components/DepositMethods/Coinbase') },
        { id: "stripe", method: "stripe-card", logo: require("../assets/card.png"), brand: "Credit / Debit Card", sandbox: false, component: () => import('../components/DepositMethods/Stripe-Card'), isChargebackEnabled: true, disabled: true, tagline: "Credit and debit card purchases are processed on checkout. Add items to your cart and click checkout to proceed with this method." },
        // { id: "alipay", method: "alipay", logo: require("../assets/alipay.png"), brand: "AliPay", sandbox: false, component: () => import('../components/DepositMethods/AliPay') },
        // { id: "wechatpay", method: "wechatpay", logo: require("../assets/wechatpayclear.png"), brand: "WeChatPay", sandbox: false, component: () => import('../components/DepositMethods/WeChatPay') },
        // { id: "paysafecard", method: "adyen", logo: require("../assets/paysafe.png"), brand: "PaySafeCard", sandbox: true, component: () => import('../components/DepositMethods/PaySafeCard')},
        // { id: "g2a", logo: require("../assets/g2apay.png"), method: "g2a", brand: "G2A" },
        // { id: "gocoin", logo: require('../assets/GoCoin.svg'), method: "gocoin", brand: "GoCoin" },
        // { id: "apple-pay", logo: require('../assets/applepay.png'), brand: "Apple" },
        // { id: "google-pay", logo: require('../assets/gpay.png'),  brand: "GPay" },
      ],
      selectedPaymentMethodId: null,
      errorLoading: false,
    }
  },
  mounted() {
    this.select('coinbase');
    // this.select('alipay');
    // this.select('coinbase');
  },
  computed: {
    selectedPaymentMethod() {
      return this.paymentMethods.find(x => x.id == this.selectedPaymentMethodId);
    },
    user() { return this.$store.getters["auth/user"] },
  },
  methods: {
    async disabledMethod() {
      swal.fire({
        title: "Contact us",
        html: "Your account has been limited due to a negative balance. Please contact customer support to resolve this issue.",
        icon: "error",
      })
    },
    async select(paymentMethodId) {
      this.previouslySelectedPaymentMethodId = this.selectedPaymentMethodId
      this.selectedPaymentMethodId = paymentMethodId;
    },
    async selectPrevious(){
      this.selectedPaymentMethodId = this.previouslySelectedPaymentMethodId;
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}
</style>